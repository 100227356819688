
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import useTranslation from 'next-translate/useTranslation'
import {
    Section,
    Grid,
    Col,
    Container,
    Heading,
    TextBlock,
    Main,
    Image
} from '@ui'
import { Faq, HowTo, Search } from '@components'
import { ReactComponent as InfoIcon } from '../assets/icons/icon-i.svg'

// import React, { useState } from 'react'
// import { get } from 'utils/api'

export default function Page() {
    const { t, lang } = useTranslation('home')

    // const [clientSecret, setClientSecret] = React.useState()
    //
    // React.useEffect(() => {
    //     const start = async () => {
    //         const response = await get('stripe-intent')
    //         if (response?.data?.client_secret) {
    //             setClientSecret(response?.data?.client_secret)
    //         }
    //     }
    //     start()
    // }, [])
    // {clientSecret && <Stripe clientSecret={clientSecret} />}

    return (
        <Main>
            <Section className="Section--home-hero">
                <Container
                    className="bg-sand-light pt-15 pb-16 m:pb-0"
                    style={{ backgroundImage: 'url("media/home-hero-bg.svg")' }}
                >
                    <Grid className="m:pt-7">
                        <Col size="50%" className="ml-auto">
                            <Heading
                                text={t('title')}
                                className="mb-5 m:mb-5"
                                size="l"
                                component="h1"
                            />
                            <TextBlock className="mb-5 m:mb-5">
                                <p>{t('text')}</p>
                            </TextBlock>
                            <Search
                                className="mr-auto"
                                label={{
                                    text: (
                                        <span className="pr-1">
                                            {t('placeholder')}
                                        </span>
                                    )
                                }}
                            />
                        </Col>
                        <Col className="hidden m:flex w-full">
                            <Image
                                className="m:mt-9"
                                src="media/home-hero-bg.svg"
                                alt={t('title')}
                            />
                        </Col>
                    </Grid>
                </Container>
            </Section>
            <Section id="how" className="py-16 m:pt-14 m:pb-17">
                <Container>
                    <HowTo />
                </Container>
            </Section>
            <Section className="Section--home-cta">
                <Container
                    className="py-13 m:py-16 bg-violet"
                    style={{ backgroundImage: 'url("media/hero-cta-bg.svg")' }}
                >
                    <Grid>
                        <Col size="50%">
                            <Heading
                                text={t('banner_title')}
                                color="white"
                                className="mb-8 m:mb-10 font-medium"
                            />
                            <TextBlock color="white" size="l">
                                <p>{t('banner_text')}</p>
                            </TextBlock>
                        </Col>
                        <Col size="50%">
                            <Search
                                className="mt-10 m:mt-6 ml-auto"
                                label={{
                                    text: (
                                        <>
                                            <span className="pr-1">
                                                {t('placeholder')}
                                            </span>
                                        </>
                                    )
                                }}
                                tooltip={
                                    <InfoIcon
                                        className="hidden m:block"
                                        width="1.9rem"
                                        height="1.9rem"
                                    />
                                }
                            />
                        </Col>
                    </Grid>
                </Container>
            </Section>
            <Section id="faq">
                <Container className="pt-15 pb-20 m:py-14">
                    <Grid className="mb-4">
                        <Col size="25%" className="pr-5 m:pr-0 m:mb-10">
                            <Heading text={t('faq_title')} />
                        </Col>
                        <Col size="75%">
                            <Faq />
                        </Col>
                    </Grid>
                </Container>
            </Section>
        </Main>
    )
}


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  